<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <!-- Payouts List -->
        <div class="row clearfix"> 
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Bank Wise Provider List</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-md-flex justify-content-end py-3">
                            <div class="payment-dropdown d-flex flex-lg-row-reverse flex-column ml-lg-3 ml-0 mt-lg-0">
                                <b-button size="lg" variant="primary btn-mw" @click="createProvider()">Add Provider <i class="fa fa-plus-square"></i></b-button>
                            </div>

                            <b-modal id="provider_modal" :title="modalTitle" hide-footer @shown="resetProviderForm">
                                <div class="modal-area">
                                    <ValidationObserver v-slot="{ passes }">
                                        <ul class="list-unstyled mb-0 payment-single-transfer">
                                            <form id="funding_option" @submit.prevent="passes(addProvider)">
                                                <li>
                                                    <ValidationProvider name="provider" rules="required" v-slot="{ errors}">
                                                        <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':providerData.provider && providerData.provider.length != 0  && !errors[0]}">
                                                            <span class="float-label">
                                                                <label for="functions">Select Provider*</label>
                                                                <multiselect
                                                                    placeholder="Select provider"
                                                                    v-model="providerData.provider"  
                                                                    :show-labels="false" 
                                                                    :options="masterProviderItems" 
                                                                    label="name"
                                                                    :searchable="true">
                                                                </multiselect> 
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check" v-if="providerData.provider && providerData.provider.length != 0 && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li>
                                                    <ValidationProvider name="currency" rules="required" v-slot="{ errors}">
                                                        <div class="form-group form-select single-multiselect"
                                                            :class="{'errors': errors[0],'success':providerData.currency && providerData.currency.length != 0  && !errors[0]}">
                                                            <span class="float-label">
                                                                <label for="functions">Select Currency*</label>
                                                                <multiselect placeholder="Select Currency" v-model="providerData.currency" :show-labels="false"
                                                                    :options="currencyItems" label="name" :searchable="true">
                                                                </multiselect>
                                                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                                <i class="fa fa-check"
                                                                    v-if="providerData.currency && providerData.currency.length != 0 && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li>  
                                                    <ValidationProvider name="assignedname" rules="required" v-slot="{ errors}">   
                                                        <div class="form-group" :class="{'errors': errors.length && !providerData.assignedname || errors[0],'success':providerData.assignedname && !errors[0]} ">
                                                            <span class="float-label">
                                                                <input type="text" v-model="providerData.assignedname" class="form-control" name="assignedname " id="assignedname" placeholder="Bank Assigned Name*">
                                                                <label for="category_name " class="control-label">Bank Assigned Name*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !providerData.assignedname || errors[0] "></i>
                                                                <i class="fa fa-check" v-if="providerData.assignedname && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li>  
                                                    <ValidationProvider name="assignedcode" rules="required" v-slot="{ errors}">   
                                                        <div class="form-group" :class="{'errors': errors.length && !providerData.assignedcode || errors[0],'success':providerData.assignedcode && !errors[0]} ">
                                                            <span class="float-label">
                                                                <input type="text" v-model="providerData.assignedcode" class="form-control" name="assignedcode" id="assignedcode" placeholder="Bank Assigned Code*">
                                                                <label for="category_name " class="control-label">Bank Assigned Code*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors.length && !providerData.assignedcode || errors[0] "></i>
                                                                <i class="fa fa-check" v-if="providerData.assignedcode && !errors[0]"></i>
                                                            </span>
                                                        </div>
                                                    </ValidationProvider>
                                                </li>
                                                <li>
                                                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                        <b-button v-if="editProviderId==''" type="submit" variant="primary btn-mw" size="lg">Add Provider </b-button>
                                                        <b-button v-if="editProviderId!=''" type="submit" variant="primary btn-mw" size="lg">Update Provider </b-button>
                                                        <b-button @click="$bvModal.hide('provider_modal')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                    </div>
                                                </li>
                                            
                                            </form>
                                        </ul>
                                    </ValidationObserver>
                                </div>
                            </b-modal> 
                        </div>
                        <b-table 
                            ref="providerTable"
                            responsive
                            hover 
                            outlined
                            table-class="js-basic-example table-custom mb-0"
                            head-variant="light"
                            :items="getProvider" 
                            :fields="providerFields"
                            :sort-direction="sortDirection" :emptyText="'No data found.'" show-empty>
                            <template #cell(actions)="row">
                                <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                    <b-dropdown-item @click="showProviderEdit(row)">Edit</b-dropdown-item>
                                    <b-dropdown-item @click="deleteProvider(row.item.id)">Delete</b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
import '@/plugins/sweetalert2'
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
import shared from "@/shared.js";
export default {
    name:'BankComponent',
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
        ValidationObserver,
        ValidationProvider,
        Multiselect: () => import('vue-multiselect'),
        "main-loader": LoaderComponent,
    },
    methods: {
        
        async getProvider(){
            try {
                this.$refs.loader.show();

                this.providerItems = [];
                let paramData = {
                    limit : 100,
                    offset : 1,
                    bank_code : sessionStorage.getItem('bankcode')//this.bankcode//bankcode
                };
                const response = await this.$http.get(this.$standapp + "customer/beneficiary/banklists/",{
                    params: paramData,
                    headers: {
                        Authorization: 'Basic YmRkMTc5NTY5MmQ1NTBiMWIwY2M0YmUzZmJkN2MyMTY6ZTczYTdhNDhjNDYzNDM5Nzg1MTUyNTI4MWE4NzdkMWY=',//"Bearer " + sessionStorage.getItem("jwtToken"),
                    },
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                if(response.data.data[0].providers){
                    response.data.data[0].providers.map((item) => {
                        let items = {
                            id:item.id,
                            providerid:item.provider_data.id,
                            name:item.provider_data.name,
                            assignname: item.assigned_name,
                            assigncode:item.assignedcode,
                            currency:item.currency
                        };
                        this.providerItems.push(items);
                    }); 
                }
                this.$refs.loader.hide();
                return this.providerItems;               
            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        },

        createProvider(){
            this.editProviderId = '';
            this.currencyList(sessionStorage.getItem('countryID'));
            this.masterProviderList();
            this.$bvModal.show('provider_modal');
        },

        async currencyList(countryid){
            try {
                let paramData = {
                    page : 1,
                    size : 100,
                    sort : 'asc',
                    sortby : 'createdat'
                    // countryid : countryid
                };
                const response = await this.$http.get(this.$baseurl + "currencies/supported/"+countryid,{
                    params: paramData,
                    headers: {
                        Authorization: 'Bearer '+ sessionStorage.getItem("jwtToken"),
                    },
                });
                // console.log(response);
                //JSON responses are automatically parsed.
                response.data.details.map((item) => {
                    let currencydata = {
                        // id:item.countryid, 
                        name: item.currencycode //'INR'
                    }
                    this.currencyItems.push(currencydata);
                })
            } catch (error) {
                console.log(error);
                // shared.toastrError(error);
            }
        },

        async masterProviderList (){
            try {
                this.$refs.loader.show();
                this.masterProviderItems = [];
                const response = await this.$http.get(this.$apiV2 + "payment/provider-list/",{
                    headers: {
                        Authorization: 'Basic ZDg3Zjk1ODlmNDdhNTk1YmFiOWUzNDg5ODAwMTg4NGQ6MGU3MzUzZGQ0ZmUyNGFlN2EzNDgzZmZkNWIzNTAyMzc=',
                    },
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.data.map((item) => {
                    let items = {
                        name:item.name,
                        id:item.providerid
                    };
                    this.masterProviderItems.push(items);
                }); 
                this.$refs.loader.hide();
                return this.masterProviderItems;               
            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        },

        resetProviderForm(){
            this.btnDesabled = false;
            if(this.editProviderId == '') {
                this.providerData.provider = null;
                this.providerData.currency = '';
                this.providerData.assignedname = '';
                this.providerData.assignedcode = '';
                this.modalTitle = 'Add Provider';
                
            } else {
                this.modalTitle = 'Edit Provider';

            }
        },  
        
        refreshProviderTable() {
            this.$refs.providerTable.refresh();
        },

        addProvider(){
            if(this.editProviderId!='') {
                this.updateProvider();   
            } else {
                this.$refs.loader.show();
                let providerData = {
                    providerid: this.providerData.provider.id,
                    bankid: sessionStorage.getItem('bankId'), //this.bankID,
                    assignedcode: this.providerData.assignedcode,
                    assigned_name: this.providerData.assignedname,
                    countryid: sessionStorage.getItem('countryID'), //this.countryID,
                    currency: this.providerData.currency.name
                };
                
                this.$http.post(this.$standapp+"customer/bank/providers/", providerData,{
                    headers: {
                        'Authorization': 'Basic ZDg3Zjk1ODlmNDdhNTk1YmFiOWUzNDg5ODAwMTg4NGQ6MGU3MzUzZGQ0ZmUyNGFlN2EzNDgzZmZkNWIzNTAyMzc='
                    },
                }).then((res) => {
                    // console.log(res);
                    if(res.data.code == '0086-000') {
                        shared.toastrSuccess('Provider Successfully Added');
                        this.refreshProviderTable();
                        this.$bvModal.hide('provider_modal');
                        this.$refs.loader.hide();
                    }
                }).catch((error) => {
                    console.log(error);
                    this.$refs.loader.hide();
                });
            }
        },

        showProviderEdit(element) {
            // console.log(element);
            this.editProviderId = element.item.id;

            this.providerData.provider = {id:element.item.providerid, name: element.item.name},
            this.providerData.currency = {name : element.item.currency},
            this.providerData.assignedname = element.item.assignname,
            this.providerData.assignedcode = element.item.assigncode

            this.$bvModal.show('provider_modal');
        },

        updateProvider() {
            this.$refs.loader.show();
            let updateData = {
                pk: this.editProviderId,
                providerid: this.providerData.provider.id,
                bankid: sessionStorage.getItem('bankId'), //this.bankID,
                assignedcode: this.providerData.assignedcode,
                assigned_name: this.providerData.assignedname,
                countryid: sessionStorage.getItem('countryID'), //this.countryID,
                currency: this.providerData.currency.name
            };
            // console.log(updateData);
            this.$http.put(this.$standapp + "customer/bank/providers/", updateData,{
                headers: {
                    'Authorization': 'Basic ZDg3Zjk1ODlmNDdhNTk1YmFiOWUzNDg5ODAwMTg4NGQ6MGU3MzUzZGQ0ZmUyNGFlN2EzNDgzZmZkNWIzNTAyMzc=' //`Basic ${token}`
                },
            }).then((res) => {
                // console.log(res);
                if(res.data.code == '0086-000') {
                    shared.toastrSuccess('Provider Successfully updated');
                    this.refreshProviderTable();
                    this.$bvModal.hide('provider_modal');
                    this.$refs.loader.hide();
                }
            }).catch((error) => {
                this.apiErrorCode = error.response.data.code;
                this.apiErrorMsg = error.response.data.message;
                this.$refs.loader.hide();
            });             
        },

        deleteProvider(providerID) {
            shared.deleteConfirm().then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    let deleteData = {
                        pk: providerID
                    };
                    this.$http.delete(this.$standapp + "customer/bank/providers/",{
                        headers: {
                            'Authorization': 'Basic ZDg3Zjk1ODlmNDdhNTk1YmFiOWUzNDg5ODAwMTg4NGQ6MGU3MzUzZGQ0ZmUyNGFlN2EzNDgzZmZkNWIzNTAyMzc=' //`Basic ${token}`
                        },
                        data : deleteData
                    }).then((res) => {
                        if(res.data.code == '0086') {
                            shared.toastrSuccess('Provider Successfully deleted');
                            this.refreshProviderTable();
                            this.$refs.loader.hide();
                        } else {
                            this.refreshProviderTable();
                            this.$refs.loader.hide();
                            shared.toastrError(res.data.message);
                        }
                    }).catch((error) => {
                        this.apiErrorCode = error.response.data.code;
                        this.apiErrorMessage = error.response.data.message;
                    });
                }
            })
        }, 
    },data(){
        return{
            modalTitle: '',
            sortDesc: false,
            sortDirection: 'asc',

            providerItems:[],
           
            //All Acounts list table header fields
            providerFields: [
                { key: 'name', label: 'Provider Name',sortable: true,thClass:'th_sortfix'},
                { key: 'assignname', label: 'Provider Assigned Name',sortable: true,thClass:'th_sortfix'},
                { key: 'assigncode', label: 'Provider Assigned Code',sortable: true,thClass:'th_sortfix'},
                { key: 'actions', label: 'Actions' },
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            currencyItems:[],
            masterProviderItems:[],
            editProviderId:'',
            providerData:{
                provider:null,
                currency:'',
                assignedname:'',
                assignedcode:''
            }
        }
    },mounted() {
    }
}
</script>
